export const DEFAULT_ERROR_MESSAGE = 'Có gì đó nó sai!';

export const CONTRACT_TYPE_VALUE = {
  BQTG: 'BQTG',
  BQTP: 'BQTP',
  SHTP: 'SHTP',
  HAPK: 'HAPK',
  THAM: 'THAM',
  HATA: 'HATA',
  QUAY: 'QUAY',
  CHUP: 'CHUP',
  THUE: 'THUE',
  HOTR: 'HOTR',
  KENH: 'KENH',
  DTHT: 'DTHT',
  KHAC: 'KHAC',
  CSĐQ: 'CSĐQ',
  FANP: 'FANP',
  TTOK: 'TTOK',
  PUBL : 'PUBL'
};

export const CONTRACT_FOR_ORIGIN_SONG = [
  CONTRACT_TYPE_VALUE.HAPK,
  CONTRACT_TYPE_VALUE.HATA,
  CONTRACT_TYPE_VALUE.THAM,
  CONTRACT_TYPE_VALUE.CHUP,
  CONTRACT_TYPE_VALUE.QUAY,
  CONTRACT_TYPE_VALUE.THUE
];
export const CONTRACT_TYPE = [
  { value: CONTRACT_TYPE_VALUE.BQTG, label: 'HĐ Bản Quyền Tác Giả' },
  { value: CONTRACT_TYPE_VALUE.BQTP, label: 'HĐ Bản Quyền Tác Phẩm' },
  { value: CONTRACT_TYPE_VALUE.SHTP, label: 'HĐ Sở Hữu Tác Phẩm' },
  { value: CONTRACT_TYPE_VALUE.HAPK, label: 'HĐ Hòa Âm Phối Khí' },
  { value: CONTRACT_TYPE_VALUE.THAM, label: 'HĐ Thu Âm' },
  { value: CONTRACT_TYPE_VALUE.HATA, label: 'HĐ Hòa Âm Thu Âm' },
  { value: CONTRACT_TYPE_VALUE.QUAY, label: 'HĐ Quay' },
  { value: CONTRACT_TYPE_VALUE.CHUP, label: 'HĐ Chụp' },
  { value: CONTRACT_TYPE_VALUE.THUE, label: 'HĐ Thuê' },
  { value: CONTRACT_TYPE_VALUE.HOTR, label: 'HĐ Hỗ trợ' },
  { value: CONTRACT_TYPE_VALUE.KENH, label: 'HĐ Kênh' },
  { value: CONTRACT_TYPE_VALUE.DTHT, label: 'HĐ Đầu Tư Hợp Tác' },
  { value: CONTRACT_TYPE_VALUE.CSĐQ, label: 'HĐ Ca Sĩ Độc Quyền' },
  { value: CONTRACT_TYPE_VALUE.FANP, label: 'HĐ Fanpage' },
  { value: CONTRACT_TYPE_VALUE.TTOK, label: 'HĐ Tiktok' },
  { value: CONTRACT_TYPE_VALUE.PUBL, label: 'HĐ Publishing' },
  { value: CONTRACT_TYPE_VALUE.KHAC, label: 'HĐ Khác' }
];
export const CONTRACT_COMPANY = [
  { value: 'NV', label: 'Nam Việt' },
  { value: 'NhacPro', label: 'Nhạc Pro' }
];

export const CONTRACT_STATUS = [
  { value: 'compose', label: 'Soạn hợp đồng' },
  { value: 'liquidated', label: 'Đã thanh lý' },
  { value: 'expired', label: 'Đã hết hạn' },
  { value: 'available', label: 'Khả dụng' }
];

export const CONTRACT_STATUS_VALUE = {
  COMPOSE: 'compose',
  LIQUIDATED: 'liquidated',
  EXPIRED: 'expired',
  AVAILABLE: 'available'
};

export const APPENDIX_PROCESS_VALUE = {
  COMPOSE: 'compose',
  DEAL: 'deal',
  PAY: 'pay',
  CHECK: 'check',
  COMPLETE: 'complete'
};

export const CONTRACT_FILTER_STATUS = [
  { value: 'compose', label: 'Soạn hợp đồng' },
  { value: 'liquidated', label: 'Đã thanh lý' },
  { value: 'expired', label: 'Đã hết hạn' },
  { value: APPENDIX_PROCESS_VALUE.DEAL, label: 'Trình đối tác ký hợp đồng' },
  { value: APPENDIX_PROCESS_VALUE.PAY, label: 'Thanh toán theo đợt' },
  { value: APPENDIX_PROCESS_VALUE.CHECK, label: 'Kiểm tra sản phẩm' },
  { value: APPENDIX_PROCESS_VALUE.COMPLETE, label: 'Hoàn tất' }
];

export const APPENDIX_PROCESS = [
  { value: APPENDIX_PROCESS_VALUE.COMPOSE, label: 'Soạn phụ lục' },
  { value: APPENDIX_PROCESS_VALUE.DEAL, label: 'Trình đối tác ký hợp đồng' },
  { value: APPENDIX_PROCESS_VALUE.PAY, label: 'Thanh toán theo đợt' },
  { value: APPENDIX_PROCESS_VALUE.CHECK, label: 'Kiểm tra sản phẩm' },
  { value: APPENDIX_PROCESS_VALUE.COMPLETE, label: 'Hoàn tất' }
];

export const APPENDIX_STATUS_VALUE = {
  NEW: 'new',
  ADDED: 'added'
};

export const APPENDIX_STATUS = [
  { value: APPENDIX_PROCESS_VALUE.COMPOSE, label: 'Soạn phụ lục' },
  { value: APPENDIX_PROCESS_VALUE.DEAL, label: 'Trình đối tác ký hợp đồng' },
  { value: APPENDIX_PROCESS_VALUE.PAY, label: 'Thanh toán theo đợt' },
  { value: APPENDIX_PROCESS_VALUE.CHECK, label: 'Kiểm tra sản phẩm' },
  { value: APPENDIX_PROCESS_VALUE.COMPLETE, label: 'Hoàn tất' }
];

export const MODEL = [
  { value: 'Contract', label: 'Hợp đồng' },
  { value: 'Appendix', label: 'Phụ lục' }
];

export const BUSINESS_TYPE_VALUE = {
  RING_BACK_TONE: 'NHACCHO',
  DIGITAL_MUSIC: 'NHACSO',
  YOUTUBE: 'YOUTUBE',
  NHACCHO_TUKINHDOANH: 'NHACCHO_TUKINHDOANH',
  OTHER: 'other'
};

export const BUSINESS_TYPE = [
  { value: 'ringTone', label: 'Nhạc chuông' },
  { value: 'NHACCHO', label: 'Nhạc chờ' },
  { value: 'NHACSO', label: 'Nhạc số' },
  { value: 'youtube', label: 'Kênh youtube' },
  { value: 'tiktok', label: 'Kênh Tiktok' },
  { value: 'other', label: 'Nền tảng khác' }
];

export const PAGE_SIZE = 10;

export const ORDER_BY_SELECT_VALUE = {
  LATEST: 'Latest',
  EARLIEST: 'Earliest',
  RECENTLY_CHANGE: 'RecentlyChange'
};

export const ORDER_BY_SELECT_DICTIONARY = {
  [ORDER_BY_SELECT_VALUE.LATEST]: 'Tạo gần nhất',
  [ORDER_BY_SELECT_VALUE.EARLIEST]: 'Tạo cũ nhất',
  [ORDER_BY_SELECT_VALUE.RECENTLY_CHANGE]: 'Cập nhật mới nhất'
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const MONTH_FORMAT = 'MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const FILTER_TYPE = {
  TEXT: 'text',
  DATE_PICKER: 'date_picker',
  RANGE_PICKER: 'range_picker',
  SELECT: 'select',
  AUTO_COMPLETE: 'auto_complete'
};

export const DEBOUNCE_VALUE = 300;

//* *************** */
//* NOTE: HARD CODE */
//* *************** */

export const ROLE_LABEL = {
  //NOTE: User
  createUser: 'Tạo nhân viên',
  updateUser: 'Cập nhật nhân viên',
  readUser: 'Truy cập nhân viên',
  deleteUser: 'Xoá nhân viên',
  //NOTE: Role
  createRole: 'Tạo vai trò',
  updateRole: 'Cập nhật vai trò',
  readRole: 'Truy cập vai trò',
  deleteRole: 'Xoá vai trò',
  //NOTE: Partner
  createPartner: 'Tạo đối tác',
  updatePartner: 'Cập nhập đối tác',
  readPartner: 'Truy cập đối tác',
  deletePartner: 'Xoá đối tác',
  //Song
  createSong: 'Tạo bài hát',
  updateSong: 'Cập nhập bài hát',
  readSong: 'Truy cập bài hát',
  deleteSong: 'Xoá bài hát',
  //Contract
  createContract: 'Tạo hợp đồng',
  updateContract: 'Cập nhập đồng',
  readContract: 'Truy cập hợp đồng',
  deleteContract: 'Xoá hợp đồng',
  //Contract
  createAppendix: 'Tạo phụ lục',
  updateAppendix: 'Cập nhập phụ lục',
  readAppendix: 'Truy cập phụ lục',
  deleteAppendix: 'Xoá phụ lục',
  //Payment
  createPayment: 'Tạo thanh toán',
  updatePayment: 'Cập nhập thanh toán',
  readPayment: 'Truy cập thanh toán',
  deletePayment: 'Xoá thanh toán',
  //Department
  createDepartment: 'Tạo phòng ban',
  updateDepartment: 'Cập nhập phòng ban',
  readDepartment: 'Truy cập phòng ban',
  deleteDepartment: 'Xoá phòng ban',
  //Song Addendum
  createSongAppendix: 'Tạo bài hát phụ lục',
  updateSongAppendix: 'Cập nhập bài hát phụ lục',
  readSongAppendix: 'Truy cập bài hát phụ lục',
  deleteSongAppendix: 'Xoá bài hát phụ lục',
  //Song Master Data
  createMasterdata: 'Tạo master data',
  updateMasterdata: 'Cập nhập master data',
  readMasterdata: 'Truy cập master data',
  deleteMasterdata: 'Xoá master data'
};

//* *************** */
//* NOTE: HARD CODE */
//* *************** */

export const MASTER_DATA_GROUP_LABEL = {
  artistType: 'Nghệ sĩ',
  musicType: 'Thể loại âm nhạc',
  bankName: 'Ngân hàng',
  typeOfBusiness: 'Lĩnh vực kinh doanh',
  fileCheckType: 'File check',
  notificationType: 'Loại thông báo',
  layout: 'Màn hình chức năng'
};

export const ROLE_CHECK = {
  ROLE: 'ROLE',
  SONG: 'SONG',
  PARTNER: 'PARTNER',
  USER: 'USER'
};

export const EDITABLE_CELL_TYPE = {
  CHECK_BOX: 'CHECK_BOX',
  TEXT: 'TEXT',
  SELECT: 'SELECT',
  SELECT_EXTEND: 'SELECT_EXTEND',
  SELECT_STATUS: 'SELECT_STATUS',
  CURRENCY: 'CURRENCY',
  DATEPICKER: 'DATEPICKER',
  TEXT_AREA: 'TEXT_AREA'
};

export const PAYMENT_STATUS = {
  undue: 'Chưa đến hạn',
  waiting: 'Chờ thanh toán',
  paid: 'Đã thanh toán'
};

export const GENDER_DICTIONARY = {
  male: 'Nam',
  female: 'Nữ'
};

export const APPROVE_STATUS = {
  waiting: 'Đợi duyệt',
  approved: 'Đã duyệt'
};

export const SINGER_RANK = [
  {
    label: 'A',
    value: '1'
  },
  {
    label: 'B',
    value: '2'
  },
  {
    label: 'C',
    value: '3'
  },
  {
    label: 'Không xếp hạng',
    value: '0'
  }
];

export const ONLINE_STATUS = [
  { value: 'public', label: 'Công khai' },
  { value: 'nonPublic', label: 'Không công khai' },
  { value: 'private', label: 'Riêng tư' },
  { value: 'deleted', label: 'Đã xoá' }
];
export const COPYRIGHT_SOURCE_VALUE = {
  BQTG: 'BQTG',
  VCPMC: 'VCPMC',
  fromSinger: 'fromSinger'
};

export const COPYRIGHT_SOURCE_TYPE = [
  { value: 'BQTG', label: 'Từ HĐ BQTG' },
  { value: 'VCPMC', label: 'Từ VCPMC' },
  { value: 'fromSinger', label: 'Từ người trình bày' }
];

export const SONG_COPYRIGHT_SOURCE_TYPE = [
  { value: 'BQTG', label: 'HĐ BQTG' },
  { value: 'VCPMC', label: 'VCPMC' }
];

export const PARTNER_TYPE = {
  INDIVIDUAL: 'individual',
  ORGANIZATION: 'organization'
};

export const MANAGER_PARTNER_TYPE = {
  MANAGER: 'manager',
  COMPANY: 'company'
};

export const SYNC_STATUS = [
  { value: 'SUCCESSFUL', label: 'SUCCESSFUL' },
  { value: 'ERROR', label: 'ERROR' }
];

export const DIGITAL_VALUE = {
  yesNamViet: 'Có - NamViet gửi',
  yesSingerNCT: 'Có - Nghệ sĩ gửi (NCT)',
  yesSingerZING: 'Có - Nghệ sĩ gửi (ZING)',
  no: 'Không có'
};

export const DIGITAL_OPTION = [
  { value: 'yesNamViet', label: 'Có - NamViet gửi' },
  { value: 'yesSingerNCT', label: 'Có - Nghệ sĩ gửi (NCT)' },
  { value: 'yesSingerZING', label: 'Có - Nghệ sĩ gửi (ZING)' },
  { value: 'no', label: 'Không có' }
];

export const TIKTOK_VALUE = {
  yesNamViet: 'Có - NamViet gửi',
  yesSinger: 'Có - Nghệ sĩ gửi ',
  no: 'Không có'
};

export const TIKTOK_OPTION = [
  { value: 'yesNamViet', label: 'Có - NamViet gửi' },
  { value: 'yesSinger', label: 'Có - Nghệ sĩ gửi' },
  { value: 'no', label: 'Không có' }
];

export const CHANNEL_TYPE_OPTION = [
  { value: 'internal', label: 'Thuộc trong hệ thống' },
  { value: 'external', label: 'Thuộc ngoài hệ thống' }
];

export const REVENUE_SHARING_PERTAGE = [
  {
    contractType: CONTRACT_TYPE_VALUE.BQTP,
    specialPertage: {
      businessType: [
        BUSINESS_TYPE_VALUE.RING_BACK_TONE,
        BUSINESS_TYPE_VALUE.DIGITAL_MUSIC,
        BUSINESS_TYPE_VALUE.NHACCHO_TUKINHDOANH
      ],
      pertage: {
        groupA: 50,
        groupBCopyRight: 20,
        groupBRelatedRight: 30
      }
    },
    pertage: {
      groupA: 50,
      groupBCopyRight: 10,
      groupBRelatedRight: 40
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.BQTG,
    pertage: {
      groupA: 90,
      groupBCopyRight: 10,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.DTHT,
    pertage: {
      groupA: 50,
      groupBCopyRight: 10,
      groupBRelatedRight: 40
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.SHTP,
    pertage: {
      groupA: 100,
      groupBCopyRight: 0,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.KENH,
    pertage: {
      groupA: 30,
      groupBCopyRight: 10,
      groupBRelatedRight: 60
    }
  },

  {
    contractType: CONTRACT_TYPE_VALUE.HAPK,
    pertage: {
      groupA: 100,
      groupBCopyRight: 0,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.THAM,
    pertage: {
      groupA: 100,
      groupBCopyRight: 0,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.HATA,
    pertage: {
      groupA: 100,
      groupBCopyRight: 0,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.QUAY,
    pertage: {
      groupA: 100,
      groupBCopyRight: 0,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.CHUP,
    pertage: {
      groupA: 100,
      groupBCopyRight: 0,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.THUE,
    pertage: {
      groupA: 100,
      groupBCopyRight: 0,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.HOTR,
    pertage: {
      groupA: 100,
      groupBCopyRight: 0,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.PUBL,
    pertage: {
      groupA: 100,
      groupBCopyRight: 0,
      groupBRelatedRight: 0
    }
  },
  {
    contractType: CONTRACT_TYPE_VALUE.KHAC,
    pertage: {
      groupA: null,
      groupBCopyRight: null,
      groupBRelatedRight: null
    }
  },

  {
    contractType: CONTRACT_TYPE_VALUE.CSĐQ,
    pertage: {
      groupA: 80,
      groupBCopyRight: 0,
      groupBRelatedRight: 20
    }
  },

  {
    contractType: CONTRACT_TYPE_VALUE.FANP,
    pertage: {
      groupA: 50,
      groupBCopyRight: 0,
      groupBRelatedRight: 50
    }
  },

  {
    contractType: CONTRACT_TYPE_VALUE.TTOK,
    pertage: {
      groupA: 50,
      groupBCopyRight: 0,
      groupBRelatedRight: 50
    }
  }
];

export const COMFIRM_APPENDIX_STATUS = {
  APPROVED: 'approved',
  DENIED: 'denied'
};

export const USER_COMFIRMED_TYPE = {
  ACCOUNTANT_TYPE: 'accountant',
  COMPOSER_TYPE: 'composer'
};
export const USER_COMFIRMED_TYPE_VALUE = {
  [USER_COMFIRMED_TYPE.ACCOUNTANT_TYPE]: 'Bên kế toán',
  [USER_COMFIRMED_TYPE.COMPOSER_TYPE]: 'Bên soạn hợp đồng'
};

export const REUP_TYPE = {
  BG: 'image',
  EF: 'effect',
  HK: 'hotkey',
  SG: 'song',
  ST: 'spectrum',
  TG: 'tag',
  VD: 'video',
  MT: 'music-type'
};

export const REUP_VIDEOS_STATUS = {
  DRAFT: { status: 'Nháp', color: 'orange' },
  MIXED: { status: 'Mixed', color: 'blue' },
  MIXED_ERROR: { status: 'Mixed lỗi', color: 'red' },
  UPLOADED: { status: 'Đã upload', color: 'green' },
  UPLOADED_ERROR: { status: 'Upload lỗi', color: 'red' }
};

export const IMPORT_FILE_TEMPLATE = {
  MODIFY_NAMVIET: 'TemplateImportModifyNamVietCode.xlsx', //Mã bài hát Nam Việt
  SONG_APPENDIX: 'TemplateImportSongAppendix.xlsx', //Bài hát trong phụ lục
  SONG_FILE: 'TemplateImportSongFile.xlsx', //Sản phẩm của bài hát - Step kiểm tra sản phẩm
  SONG_PLAYLIST: 'TemplateImportSongPlaylist.xlsx', //Bài hát của tuyển tập - Trang chi tiết tuyển tập
  SONG_PLAYLIST_ALL_LINK: 'TemplateImportSongPlaylistAllLink.xlsx', //Link của tuyển tập
  SONG_PUBLICATION_LINK: 'TemplateImportSongPublicationLink.xlsx' //Link phát hành
};

export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video'
};
export const MEDIA_TYPE_VALUE = {
  [MEDIA_TYPE.IMAGE]: 'Hình ảnh',
  [MEDIA_TYPE.VIDEO]: 'Video'
};
export const MEDIA_TYPE_VALUE_OPTIONS = [
  { value: MEDIA_TYPE.IMAGE, label: MEDIA_TYPE_VALUE[MEDIA_TYPE.IMAGE] },
  { value: MEDIA_TYPE.VIDEO, label: MEDIA_TYPE_VALUE[MEDIA_TYPE.VIDEO] }
];

export const REPRESENTATION_TYPE = {
  company: 'Công ty',
  manager: 'Quản lý',
  '': 'Không có'
};
